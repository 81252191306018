import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import axios from "axios"
import * as qs from "query-string"
import $ from "jquery"

function IndividualContactForm(props) {
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [ipAddress, setIpAddress] = useState("");
  const [errormsg, setErrorMsg] = useState("Highlighted fields are required");
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
    {
      element: "config",
      formname: "Contact",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for your interest. We will be in touch with you shortly. ",
      email_temp_user: "individual_contact_user",
      email_temp_admin: "individual_contact_admin",
      email_subject_user: `Contact`,
      email_subject_admin: "Contact",
      email_server_func: "arrange_a_viewing",
      event_tracking: "Contact - ",
      page_url: "/general-enquiry"
    },
    {
      grpmd: "6",
      label: "First Name*",
      placeholder: "Your first name",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18 mb-4",
    },
    {
      grpmd: "6",
      label: "Last Name*",
      placeholder: "Your last name",
      name: "lastname",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "6",
      label: "Email*",
      placeholder: "Your email address",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",

    },
    {
      grpmd: "6",
      label: "Telephone*",
      placeholder: "Your telephone number",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",

    }, 
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Write your message here",
      name: "message",
      element: "textarea",
      class: "mb-4",
      rows: "2"
    },
    {
      name: "Submit Enquiry",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 text-center",
      class: "btn",
      formclass: ""
    },
    {
      step: "col-md-12",
      text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms & Conditions</a> and <a href="/privacy-policy/">Privacy Policy</a>.',
      element: "html",
      class: "apply"
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
    {
      name: "negotiatoremail",
      type: "hidden",
      element: "input",
      defaultValue: props.subject?.negotiator?.Email
    }
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {

    if (token !== '') {

      const processFromData = async () => {
        setShowerror(false);

        formvalues['g-recaptcha-response'] = token;
        const page_url = typeof window !== 'undefined' ? window.location.href : ''


        let formData = new FormData();
        formvalues['name'] = formvalues.name;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;
        // formvalues['address'] = {
        //   "stno": props.subject.address ? props.subject.house_name_number : '',
        //   "street": props.subject.address ? props.subject.street : '',
        //   "district": props.subject.address ? props.subject.district : '',
        //   "post_town": props.subject.address ? props.subject.town : '',
        //   "county": props.subject.address ? props.subject.county : '',
        //   "postcode": props.subject.address ? props.subject.postcode : ''
        // }

        formvalues['extra'] = JSON.stringify(formvalues);
        // formvalues['ip_address'] = public_ip;  

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          // lets send mail

          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        });

        const url = typeof window !== 'undefined' ? window.location.href : ''
        if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
          fields[0].formname = 'Get mortgage help';
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname +" - "+ props.person_name,
          'formLabel': fields[0].formname +" - "+ props.person_name
        });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setErrorMsg("Highlighted fields are required");
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      event.preventDefault();
      setShowerror(false);
      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname; 
      // json['property_list'] = props.subject.properties ? props.subject.properties : '';
      json['g-recaptcha-response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();
      // setToken("aaa")

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate()
      setStartTime()

    }
  };

  const url = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <div className="form stbform valuation-main book-a-viewing-form">
      <div ref={myRef} />
      <div className="form stbform">


        <Form className="form contact-landing-form customer-complaint-form" name={fields[0].formname} method="post" noValidate validated={validated} onSubmit={handleSubmit}>

          {showerror && <div className="alert alert-danger alert-error">
            <p>{errormsg}</p>
          </div>}

          {showthankyou && <div className="alert alert-success">
            <p>{fields[0].success_text}</p>
          </div>}

          <input type="hidden" name="form_name" value={fields[0].formname} />
          <input type="hidden" name="form_type" value={fields[0].form_type} /> 
          <input type="hidden" name="to_email_id" value={props.to_email_id ? props.to_email_id : ''} />
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form_type_id" value="1" />
          <input type="hidden" name="form_page" value={url} />
          <input type="hidden" name="brand_id" value={process.env.GATSBY_STRAPI_BRAND_ID} /> 
          <div className="row">
            {fields.map((field, index) => {
              if ("input" === field.element) {
                return (
                  <InputField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    type={field.type}
                    startDate={field.type === 'date_time' ? startDate : ''}
                    setStartDate={field.type === 'date_time' ? setStartDate : ''}
                    startTime={field.type === 'timePicker' ? startTime : ''}
                    setStartTime={field.type === 'timePicker' ? setStartTime : ''}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    label={field.label}
                    labelClass={field.labelClass}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    pattern={field.patternchk}
                    handlechange={handlechange}
                    defaultValue={field.defaultValue}
                  />
                );
              }
              if ("select" === field.element) {
                return (
                  <SelectField
                    name={field.name}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    required={field.required}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    values={field.values}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    componentprops={props}
                  />
                );
              }
              if ("textarea" === field.element) {
                return (
                  <TextAreaField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    label={field.label}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                  />
                );
              }
              if ("checkbox" === field.element) {
                return (
                  <CheckboxField
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={handlechange}
                  />
                );
              }
              if ("radio" === field.element) {
                return (
                  <RadioField
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    checked={field.checked}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={handlechange}
                    lastchild={field.lastchild}
                  />
                );
              }
              if ("html" === field.element) {
                return (
                  <HtmlBox
                    text={field.text}
                    fieldClass={field.class}
                    key={`${field.element}~${index}`}
                  />
                );
              }
              if ("captcha" === field.element) {
                return (
                  <ReCaptchaBox
                    fieldClass={field.class}
                    captRef={field.captchaRef}
                    key={`${field.element}~${index}`}
                    handleonVerify={handleonVerify}
                  />
                );
              }
              if ("button" === field.element) {
                return (
                  <Form.Group>
                    <ButtonField
                      name={field.name}
                      fieldClass={field.class}
                      step={field.step}
                      formclass={field.formclass}
                      type={field.type}
                      value={field.value}
                      key={`${field.element}~${index}`}
                    />
                  </Form.Group>
                );
              }
            })
            }
          </div>
        </Form>
      </div>
    </div>
  );
}


const IndividualContact = (props) => (
  <IndividualContactForm subject={props} to_email_id={props.to_email_id} person_name={props.person_name}/>
)

export default IndividualContact
