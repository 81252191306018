import { Link } from 'gatsby'
import React from 'react'
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { aboutPage, newsPage, teamPage } from '../common/site/constants';
import './PageNav.scss';
const PageNav = (props) => {
    return (
        <section className='page-nav'>
            <Container>
                <Row>
                    <Col>
                        <ul>
                            <li>
                                <Link to="/" className="nav-links">Home</Link>
                            </li>
                            <li>
                                <Link to={aboutPage} className="nav-links">About</Link>
                            </li>
                            <li>
                                <Link to={props.pagetwo?teamPage:newsPage} className="nav-links">{props.pagetwo ? props.pagetwo : "News & Videos"}</Link>
                            </li>
                            <li>
                                <div className="nav-links">{props.page}</div>
                            </li>
                            {/* {
                                props.navList.map((item, index) => {
                                    return <li>{index}
                                        <Link to="../" className="nav-links" key={index}>{item}</Link>
                                    </li>
                                })
                            } */}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PageNav