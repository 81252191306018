import React, { useState, useEffect } from "react";
import parse from "html-react-parser"
import { Col, Container, Row } from "react-bootstrap";
import './Team.scss';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

const TeamDetail = (props) => {
    
  let processedImages = JSON.stringify({});
  if (props.imagetransforms?.Image_Transforms) {
    processedImages = props.imagetransforms.Image_Transforms;
  }

    return (
        <div className="team-detail-content">
            <Container>
                <Row>
                    <Col lg={7} md={12}>
                        <div className="person-content">
                            <h1>{props.name}</h1>
                            <p className="designation">{props.designation}</p>

                            {/* <div className="btn-wrap">
                                <button className="btn" onClick={props.openSendfrndformModal}>Email {props.name.split(' ')[0]}</button>
                                {props.mobile_no &&
                                <a href={`tel:${props.mobile_no}`} className="btn btn-secondary">{props.mobile_no}</a> }
                            </div> */}

                            {props.content ?
                            <div className="main-content">
                                {parse(props.content)}
                            </div> : '' }
                        </div>
                    </Col>
                    <Col lg={5} md={12}>
                        <div className="people-img d-none d-lg-block">
                            {props.image?.url &&
                            
                                <ImageTransform imagesources={props.image?.url} renderer="srcSet" imagename="teams.Image.detail" attr={{ alt: `${props.name} - Arabian Estates`, class: '' }} imagetransformresult={processedImages} id={props.id} format="source" />
                            //    <img src={props.image?.url} alt={props.name} />
                            }
                         
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TeamDetail