import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql } from "gatsby"
import 'bootstrap/scss/bootstrap.scss'
import "../scss/global.scss";
import "../scss/grunticon.scss"
import "slick-carousel/slick/slick.css";
import "../scss/custom.scss";
import Layout from "../components/layout"
import SEO from "../components/seo"
import ModalTiny from "react-bootstrap/Modal"
import IndividualContact from "../components/forms/individual-contact-form"
import PageNav from "../components/PageNav/PageNav";
import TeamDetails from "../components/Team/TeamDetails";

import ImageTransform from "../common/ggfx-client/module/components/image-transform";



const TeamTemplate = (props) => {
    const GQLPage = props.data?.glstrapi?.team
    const GlobalPage = props.data?.glstrapi?.globalModule;
    const [modalSendfrndformOpen, setSendfrndformOpen] = React.useState(false);
    const openSendfrndformModal = () => {
        setSendfrndformOpen(true);
    }
    const closeSendfrndformModal = () => {
        setSendfrndformOpen(false);
    }
    
  let processedImages = JSON.stringify({});
  if (GQLPage.imagetransforms?.Image_Transforms) {
    processedImages = GQLPage.imagetransforms.Image_Transforms;
  }

    return (
        <div className={'white-header team-details-page news-details-page team-detail-page'}>
            <SEO title={GQLPage.name + " | "+GQLPage.designation+" | Arabian Estates"} description={`Get to know about ${GQLPage.name} here. Contact one of real estate agents for assistance in finding the right properties for you.`} />
            <Layout>
                <div className="news-details-page">
                    <Container className="d-block d-lg-none">
                        <Row>
                            <Col>
                                {GQLPage.image?.url &&
                                    <ImageTransform imagesources={GQLPage.image?.url} renderer="srcSet" imagename="teams.Image.detail" attr={{ alt: `${GQLPage?.name} - Arabian Estates`, class: '' }} imagetransformresult={processedImages} id={GQLPage.id} format="source" />
                                    // <img src={GQLPage.image?.url} alt={props.name} className="mobile-person-img" />
                                }

                                <div className="mobile-btn">
                                    <a href="javascript:void(0)" className="btn" onClick={openSendfrndformModal}>Email {GQLPage.name.split(' ')[0]}</a>
                                    {GQLPage.mobile_no ?
                                        <a href={`tel:${GQLPage.mobile_no}`} className="btn btn-secondary">{GQLPage.mobile_no}</a> : <a href={`tel:${GlobalPage.Phone}`} className="btn btn-secondary">{GlobalPage.Phone}</a>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <PageNav page={GQLPage.name} pagetwo="People" />
                    <TeamDetails {...GQLPage} openSendfrndformModal={openSendfrndformModal} />
                    <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="book-a-viewing-modal modal-main-form modal">
                        <i className='icon-close' onClick={() => closeSendfrndformModal()}></i>

                        <ModalTiny.Body className="people-details-form-modal event-section">
                            <h2>Contact {GQLPage.name}</h2>
                            <p>For more information, please leave a few details below and a member of our team will be in contact soon.</p>
                            <IndividualContact to_email_id={GQLPage.email} person_name={GQLPage.email}/>
                        </ModalTiny.Body>

                    </ModalTiny>
                </div>
            </Layout>
        </div>
    )
}

export default TeamTemplate


export const pageQuery = graphql`
  query GetTeam($id: ID!) {
    glstrapi {
        team(id: $id, publicationState: LIVE) {
            name
            id
            mobile_no
            video_url
            content
            designation
            email
            imagetransforms
            image {
              url
              alternativeText
            }
        }
        
        globalModule {
            Phone
        }
    }
  }
`